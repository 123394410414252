import React from 'react';
import { Outlet } from 'react-router-dom';

import UsersList from '../pages/users/UserList';

const AllProjects = React.lazy(() => import('../pages/projects/List'));
const CreateProject = React.lazy(()=> import('../pages/projects/CreateProject'));
const WBSBoard = React.lazy(()=> import('../pages/wbs/Board'));
const TimeCardList = React.lazy(()=>import('../pages/time-cards/List'));
const ThisWeekTimeCards = React.lazy(()=>import('../pages/time-cards/ThisWeek'));
// const AddTimeCard = React.lazy(()=> import('../pages/time-cards/Create'));
const SubmittedWeeklyTotal = React.lazy(()=>import('../pages/time-cards/SubmittedWeeklyTotalTimecards'));


export const AdminRoutes = [
    { path: 'users/list', element: <UsersList />, name: "MyCustomers", title: "My Customers" },
    // { path: 'pre-registrations', element: <PreRegisteredUserList />, name: "Pre Registrations", title: "Pre registered users" },
    // { path: 'pre-registrations', element: <PreRegisteredUserList />, name: "Pre Registrations", title: "Pre registered users" },
    // { path: 'pre-registrations', element: <PreRegisteredUserList />, name: "Pre Registrations", title: "Pre registered users" },

    {
        title: "Projects",
        element: <Outlet />,
        path: "projects",
        icon: "grommet-icons:projects",
        name: "Projects",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'all', icon: "carbon:request-quote", title: "All Projects", element: <AllProjects />, name: "All Projects" },
            { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
        ]
    },
    {
        title: "WBS",
        element: <Outlet />,
        path: "wbs",
        icon: "grommet-icons:projects",
        name: "WBS",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'board', icon: "carbon:request-quote", title: "All Projects", element: <WBSBoard />, name: "WBS Board" },
        ]
    },
    {
        title: "Time Cards",
        element: <Outlet />,
        path: "time-cards",
        icon: "grommet-icons:projects",
        name: "Time Cards",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            // { path: 'add', icon: "carbon:request-quote", title: "Add Time Card", element: <AddTimeCard />, name: "Add Time Card" },
            { path: 'this-week', icon: "carbon:request-quote", title: "This Week Time Cards", element: <ThisWeekTimeCards />, name: "This Week Time Cards" },
            { path: 'all', icon: "carbon:request-quote", title: "All Time Cards", element: <TimeCardList />, name: "Time Cards" },
            { path: 'weekly/submitted', icon: "carbon:request-quote", title: "Submitted Weekly Total", element: <SubmittedWeeklyTotal />, name: "Submitted Weekly Total" },
        ]
    }
]