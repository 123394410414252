export const actions = {
    agent_started: {
        icon:"svg-spinners:3-dots-fade",
        systemText:'agent_started',
        displayText:' '
    },
    consultation_tool: {
        systemText:'consultation_tool',
        displayText:"Setting schedule for consultancy ... ",
        icon:"svg-spinners:3-dots-fade",
    },
    feedback:{
        icon:'',
        systemText:'Feedback',
        displayText:'Feedback'
    },
    topic_ended: {
        icon:"svg-spinners:3-dots-fade",
        systemText:'topic_ended',
        displayText:'Topic Ended'
    },
    calling_supervisor: {
        systemText:'calling_supervisor',
        displayText:"Calling Supervisor ... ",
        icon:"svg-spinners:3-dots-fade",
    },
    booking_appointment:{
        icon:'',
        systemText:'booking_appointment',
        displayText:'Scheduling an appointment...'
    },
    searching_knowledge: {
        icon:"svg-spinners:3-dots-fade",
        systemText:'searching_knowledge',
        displayText:'Searching my knowledge'
    },
    searching: {
        systemText:'searching',
        displayText:"Setting schedule for consultancy ... ",
        icon:"svg-spinners:3-dots-fade",
    },
    searching_web:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'searching_web',
        displayText:'Surfing web ...'
    },
    final_answer:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'final_answer',
        displayText:'Typing'
    },
    nursing_syllabus_retriever:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'nursing_syllabus_retriever',
        displayText:'searching private knowledge'
    },
    suggest_nearby_doctors:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'suggest_nearby_doctors',
        displayText:'searching doctors nearby'
    },
    notebook_tool:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'notebook_tool',
        displayText:'noting down conversation summary'
    },
};

export const USER_ROLES={
    EMPLOYEE:"EMPLOYEE",
    ADMIN:"ADMIN",
    FULL_TIME:"Full-Time",
    PART_TIME:"Part-Time",
    INVENTORY_OPERATOR:"Inventory Operator",
    PROJECT_LEAD:"Project Lead",
    PROJECT_MANAGER:"Project Manager"
}

export const API_PATHS= {
    REFRESH_TOKEN: `token/refresh/`,
    SIGN_IN: `users/login/`,
    SIGN_UP: `users/registration/`,
    FORGOT_PASSWORD: `users/forgot_password/`,
    UPDATE_PROFILE: `users/update/`,
    UPDATE_PHOTO: `users/update/photo`,
    USER_PROFILE: `users/detail/`,
    USER_ALL: `users/all/`,
    RESET_PASSWORD: (token) => `reset_password/${token}`,
    CHANGE_PASSWORD: `update_password`,
    ASSIGNEE_LIST : (str=null)=> str?`users/all/?q=${str}`:`users/all/`,
    SUBTASK_ASSIGNEE_LIST : (id,str=null)=> str?`projects/subtask/${id}/assignees/?q=${str}`:`projects/subtask/${id}/assignees/`,
    TDO_LIST: (str=null)=> str? `projects/tdo/all/?q=${str}`:`projects/tdo/all/`,
    SUBTASK_LIST: (str=null)=> str? `projects/subtask/all/?q=${str}`:`projects/subtask/all/`,
    CREATE_PROJECT: `projects/create/`,
    UPDATE_PROJECT: `projects/update/`,
    ALL_PROJECTS: (query) => `projects/all/?${query}`,
    PROJECT_DETAIL: (id) => `projects/detail/${id}/`,
    EMPLOYEE_WBS_LIST: (id) => `wbs/employee/${id}/`,
    WBS_LIST: `wbs/all/`,
    WBS_STATUS_UPDATE: (id)=>`wbs/update/status/${id}/`,
    WBS_END_DATE_UPDATE: (id)=>`wbs/update/end_date/${id}/`,
    WBS_DETAIL: (id)=>`wbs/detail/${id}/`,
    CREATE_WBS: `wbs/create/`,
    TIMECARDS: `wbs/timecards/list/`,
    TIMECARDS_ALL: `wbs/timecards/list/all/`,
    HOUR_TYPES:`organizations/hour-types/`,
    ADD_TIMECARD: `wbs/timecards/add/`,
    EDIT_TIMECARD: (id)=>`wbs/timecards/edit/${id}/`,
    SUBMIT_TIMECARDS: `wbs/timecards/submit/`
};


export const TOKEN = "access-token"
export const REFRESH_TOKEN = "refresh-token"
export const USER_ID = "user_id"
export const USER_GROUPS = "user_groups"
export const USER_DATA = "user_data"
export const PERMISSIONS = "user_permissions"